import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Main from '../components/main';
import { Action } from 'mediasoil-shared-components/components/';
import Locations from '../components/locations';

export default () => (
  <Layout>
    <SEO title="Contact Us" />
    <Main>
      <h1>Contact Us</h1>
      <p>
        Thank you for visiting our website and we hope that you were able to find the information you needed! If not,
        feel free to contact us using the information below.
      </p>
      <p>
        <strong>Owner, Chris Cullison:</strong>
      </p>
      <div>
        <strong>Phone:</strong>
        <p>
          <Action href="tel:1-402-230-9180">402-230-9180</Action>
        </p>
      </div>
      <div>
        <strong>Email:</strong>
        <p>
          <Action href="mailto:cncfireworksshows@gmail.com">cncfireworksshows@gmail.com</Action>
        </p>
      </div>
      <h2>Tent Information</h2>
      <Locations />
    </Main>
  </Layout>
);
